import Axios from "axios";
import Cookies from "js-cookie";
class WinnersApi {
  async getAll() {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/codes/winners-admin`,
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async getForExport(dateFrom, dateTo, searchText, searchBy) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/codes/code-attempts/export`,
        { dateFrom, dateTo, searchText, searchBy },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new WinnersApi();
